'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import {
  fetchPermissions,
  getAccessTokenFromRefreshToken,
  isMySessionValid,
} from '@/services/userService';
import { getCookie, setCookie } from 'cookies-next';

const StoreTokens = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = searchParams.get('accessToken') as string;
      const refreshToken = searchParams.get('refreshToken');
      const tenantName = searchParams.get('tenantName') as string;
      const tenantId = searchParams.get('tenantId') as string;

      if (accessToken && refreshToken) {
        setCookie('accessToken', accessToken);
        setCookie('refreshToken', refreshToken);
        setCookie('tenantName', tenantName);
        setCookie('tenantId', tenantId);
      }
      const storedAccessToken = getCookie('accessToken') as string;
      const storedRefreshToken = getCookie('refreshToken') as string;
      const storedTenantName = getCookie('tenantName') as string;
      const storedTenantId = getCookie('tenantId') as string;

      const sessionValid = await isMySessionValid(
        storedTenantId,
        storedTenantName,
        storedAccessToken
      );

      if (!sessionValid) {
        const newTokens =
          await getAccessTokenFromRefreshToken(storedRefreshToken);
        setCookie('accessToken', newTokens.accessToken);
        setCookie('refreshToken', newTokens.refreshToken);
      }
      // Decode the access token to extract user information
      if (storedAccessToken) {
        try {
          const permission = await fetchPermissions(
            storedTenantId,
            storedTenantName,
            storedAccessToken
          );

          const userPermissionsString = JSON.stringify(permission?.data);
          setCookie('userPermissions', userPermissionsString);
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      }
    };

    fetchData();
  }, [searchParams]);

  return null;
};

export default StoreTokens;
