import axios from 'axios';
import toast from 'react-hot-toast';

export const getUserDetails = async (
  id: string,
  tenantId: any,
  tenantName: any,
  accessToken: any
) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/users/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const userData = await response?.data;
    return userData;
  } catch (error) {
    console.log('Error in getting user info:', error);
    return {};
  }
};

export const editUserDetails = async (
  userId: string,
  updateValues: any,
  tenantId: any,
  tenantName: any,
  accessToken: any
) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/users/${userId}`,
      updateValues,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log('Error updating user details:', error);
    return { status: 400, error };
  }
};

export const uploadImageToS3 = async (
  userId: string,
  file: any,
  tenantId: any,
  tenantName: any,
  accessToken: any
) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/users/upload/image/${userId}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response?.data;
  } catch (e: any) {
    if (e.response && e.response.status === 413) {
      toast.error(
        'Image size exceeds the maximum allowed limit. Please upload a smaller image.'
      );
    } else {
      toast.error(
        'Something went wrong while uploading the image. Please try again.'
      );
    }
  }
};

export async function getUserByToken(
  tenantId: string,
  tenantName: string,
  token: string
) {
  if (
    tenantId &&
    tenantId !== 'null' &&
    tenantName &&
    tenantName !== 'null' &&
    token &&
    token !== 'null'
  ) {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-TENANT-ID': tenantId,
            'X-TENANT-NAME': tenantName,
          },
        }
      );

      return response?.data?.data;
    } catch (error) {
      console.error(`Error in getting user by token:`, error);
    }
  }
}

export async function isMySessionValid(
  tenantId: string,
  tenantName: string,
  token: string
) {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/validate/session`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    throw new Error('Token expired.');
  }
}
export async function getAccessTokenFromRefreshToken(refreshToken: string) {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/token/generate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate access token');
  }
}

export async function fetchPermissions(
  tenantId: any,
  tenantName: any,
  accessToken: any
) {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/me/permission`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error getting user's permission:`, error);
  }
}
